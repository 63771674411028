body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Droid Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    flex-row: 1
}

.App-header {
    background-color: rgb(53, 110, 176);
    height: 76px;
    color: white;
}

.App-logo {
    height: 48px;
    float: left;
    margin: 10px;
}

.App-content {
    box-sizing: border-box;
    margin: 10px auto 10px auto;
    padding: 0 10px 0 10px;
    width: 100%;
    max-width: 800px;
}

.flex {
    flex: 1
}

.topNavigation {
    margin-top: 20px;
}

.MuiButtonBase-root.inputMargin {
    margin: 8px;
}

.MuiFormControl-root.inputMargin {
    margin: 8px;
}

.MuiFormControlLabel-root.inputMargin {
    margin: 0 8px 0 -3px;
}

.MuiAutocomplete-root.inputMargin {
    margin: 8px;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px!important;
}
